import React from "react";
import './About.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import photoLevs from "../work/photoLevs/photoLevs";
import DesktopNavigation from "../navigation/DesktopNavigation";

function DesktopAbout() {
    const width = "100%";
    return ( 
        <React.Fragment>
        <Helmet>
          <title>photoLevs: About</title>
          <meta name="description" content="Joseph Levermore, born in Birmingham, United Kingdom, was brought up in a family passionate..." />
          <meta property="og:title" content="photoLevs: About" />
        </Helmet>
            <div className="layout">
        <div className="sidebar">
          <DesktopNavigation />
        </div>
            <div className="row-about">
                <div className="column">
                    <div class="vertical-center">
                        <h2 className = "about">About Joseph</h2>
                        <p className = "about">
                            Joseph Levermore, born in Birmingham, United Kingdom, was raised in a family passionate about art and its ability to express an individual's daily experiences. In realising the emotive power of photography, Joseph from a young age, became captivated and thus began his journey.
                        </p>
                        <p className = "about">
                            In a world bursting with colour, Joseph wishes to challenge his audience by experiencing the world of duality: Black and White. Joseph sees Black and White photography as a medium to inspire individuals to project their own stories to images devoid of colour, but flushing in beauty.
                        </p>
                        <p className = "about">
                            He is primarily interested in communicating the everyday experiences of city folk across the United Kingdom, and much of the work displayed here will cover those experiences. His photography is not centralised on human-based subjects but on the cityscape in the main.
                        </p>
                        <p className = "about">
                            He hopes you enjoy his impressions of the United Kingdom's cityscape. The stories captured through his lens are yours to tell….
                        </p>
                    </div>
                </div>
                <div className="column">
                    <div class="vertical-center">
                    <div className = "imageContainer">
                            <LazyLoadImage
                                src={photoLevs.imageSrc} // use normal <img> attributes as props
                                width = {width}
                                alt = {photoLevs.desc}
                            />  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
        );
    }
    export default DesktopAbout;