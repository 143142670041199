import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';

function Contact() {
  const [isMobile, setIsMobile] = useState(false);
  
  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const styles = {
    container: {
      padding: isMobile ? '20px 16px' : '40px 20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    pageTitle: {
      fontSize: isMobile ? '28px' : '32px',
      fontWeight: '700',
      marginBottom: '32px',
      textAlign: isMobile ? 'center' : 'left',
      color: '#333',
    },
    contactContainer: {
      maxWidth: isMobile ? '100%' : '600px',
      margin: '0 auto',
      backgroundColor: '#fff',
      padding: '30px',
      borderRadius: '10px',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: '600',
      marginBottom: '24px',
      color: '#333',
      textAlign: 'center',
    },
    contactInfo: {
      fontSize: isMobile ? '18px' : '20px',
      lineHeight: '2.2',
      marginBottom: '16px',
      color: '#444',
      textAlign: 'center',
      width: '100%',
    },
    contactLink: {
      color: '#0066cc',
      textDecoration: 'none',
      transition: 'color 0.2s ease',
      fontWeight: '500',
    },
    divider: {
      margin: '20px 0',
      height: '1px',
      backgroundColor: '#e0e0e0',
      width: '100%',
    },
    footer: {
      marginTop: '40px',
      textAlign: 'center',
      fontSize: '14px',
      color: '#666',
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>photoLevs: Contact Information</title>
        <meta name="description" content="Contact Joseph Levermore trading as photoLevs via email, LinkedIn, or Instagram." />
        <meta property="og:title" content="photoLevs: Contact Information" />
      </Helmet>
      
      <div style={styles.container}>
        <h2 style={styles.pageTitle}>Contact Information</h2>
        <div style={styles.footer}>
          <p>For business inquiries, please allow 1-2 business days for a response.</p>
        </div>
        <div style={styles.contactContainer}>
          <p style={styles.contactInfo}>
            <span style={{fontWeight: 'bold'}}>Email:</span>{' '}
            <a 
              href="mailto:levermorejoseph@gmail.com" 
              style={styles.contactLink}
              onMouseEnter={(e) => e.target.style.color = '#004499'}
              onMouseLeave={(e) => e.target.style.color = '#0066cc'}
            >
              levermorejoseph@gmail.com
            </a>
          </p>
          
          <div style={styles.divider}></div>
          
          <p style={styles.contactInfo}>
            <span style={{fontWeight: 'bold'}}>LinkedIn:</span>{' '}
            <a 
              href="https://www.linkedin.com/in/joseph-michael-levermore" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.contactLink}
              onMouseEnter={(e) => e.target.style.color = '#004499'}
              onMouseLeave={(e) => e.target.style.color = '#0066cc'}
            >
              Joseph Michael Levermore
            </a>
          </p>
          
          <div style={styles.divider}></div>
          
          <p style={styles.contactInfo}>
            <span style={{fontWeight: 'bold'}}>Instagram:</span>{' '}
            <a 
              href="https://www.instagram.com/photolevs" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.contactLink}
              onMouseEnter={(e) => e.target.style.color = '#004499'}
              onMouseLeave={(e) => e.target.style.color = '#0066cc'}
            >
              photolevs
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contact;