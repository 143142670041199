import React, { useState, useEffect, useCallback, memo } from 'react';
import { useInView } from 'react-intersection-observer';

// Memoized Image component to prevent unnecessary re-renders
const GalleryImage = memo(({ src, alt, style }) => {
  const [error, setError] = useState(false);
  
  return error ? (
    <div className="error-container" style={style}>
      Failed to load image
    </div>
  ) : (
    <img 
      src={src} 
      alt={alt} 
      style={style}
      onError={() => setError(true)} 
      className="gallery-image"
    />
  );
});

// Memoized Navigation Arrow component
const NavigationArrow = memo(({ direction, onClick }) => {
  const arrowStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '2rem',
    color: 'white',
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 10,
    ...(direction === 'left' ? { left: '10px' } : { right: '10px' }),
  };

  return (
    <div style={arrowStyle} onClick={onClick}>
      {direction === 'left' ? '←' : '→'}
    </div>
  );
});

// Description Component
const ImageDescription = memo(({ caption, description, location }) => {
  const descriptionStyle = {
    padding: '10px',
    textAlign: 'center',
    color: '#333',
    backgroundColor: 'white',
    width: '100%',
  };

  return (
    <div style={descriptionStyle}>
      {location && <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{location}</div>}
      {caption && <div>{caption}</div>}
      {description && <div style={{ fontSize: '0.9rem', marginTop: '5px' }}>{description}</div>}
    </div>
  );
});

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Image Gallery Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div className="error-message">Something went wrong. Please try again later.</div>;
    }

    return this.props.children;
  }
}

// Main Gallery Component
const MobileImageGallery = ({ images, inTravelPage = false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  // Get the current image
  const currentImage = images && images.length > 0 ? images[currentIndex] : null;
  
  // Navigation handlers
  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  }, [images]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  }, [images]);

  // Preload adjacent images for smoother navigation
  useEffect(() => {
    if (!images || images.length === 0) return;

    const preloadImage = (index) => {
      const img = new Image();
      img.src = images[index].imageSrc;
    };

    const nextIndex = (currentIndex + 1) % images.length;
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    
    preloadImage(nextIndex);
    preloadImage(prevIndex);
    
    setIsLoading(false);
  }, [currentIndex, images]);

  // If no images or loading, show a loading state
  if (!images || images.length === 0 || !currentImage) {
    return <div className="loading">No images available</div>;
  }

  // Set padding based on where component is being used
  const containerStyle = {
    position: 'relative',
    padding: inTravelPage ? '0' : '20px', // No padding if in travel page, 20px if in work component
    overflow: 'hidden',
    backgroundColor: '#fff',
  };

  const imageContainerStyle = {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 80px)', // Reserve space for description
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Maintain aspect ratio while filling container
  };

  const descriptionContainerStyle = {
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    padding: '10px 0',
    zIndex: 5,
  };

  return (
    <ErrorBoundary>
      <div style={containerStyle}>
        <div style={imageContainerStyle}>
          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            <GalleryImage 
              src={currentImage.imageSrc} 
              alt={currentImage.caption || 'Gallery image'} 
              style={imageStyle}
            />
          )}
          
          <NavigationArrow direction="left" onClick={goToPrevious} />
          <NavigationArrow direction="right" onClick={goToNext} />
        </div>
        
        <div style={descriptionContainerStyle}>
          <ImageDescription 
            caption={currentImage.caption} 
            description={currentImage.desc} 
            location={currentImage.location} 
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MobileImageGallery;