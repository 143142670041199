import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DesktopNavigation, { travelData } from '../../navigation/DesktopNavigation';
import Header from '../../header/Header';
import MobileImageGallery from '../MobileImageGallery';

const TravelPageTemplate = () => {
  const { country } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  
  // CSS variables
  const bsGutterX = '1.5rem';
  const bsGutterY = '0';
  
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const data = travelData[country];
  if (!data) {
    return <div>No data found for this country</div>;
  }
  
  // Format images for MobileImageGallery
  const formattedImages = data.gridImages.map((src, index) => ({
    imageSrc: src,
    caption: `${data.title} - Image ${index + 1}`,
    desc: data.claim,
    location: country
  }));
  
  const openModal = (image) => {
    setSelectedImage(image);
  };
  
  const closeModal = () => {
    setSelectedImage(null);
  };

  const styles = {
    // Common styles for both mobile and desktop
    layout: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
    },
    sidebar: {
      display: isMobile ? 'none' : 'block',
      width: '200px',
      flexShrink: 0,
      position: 'sticky',
      top: '0',
      height: '50vh',
      padding: '20px',
    },
    content: {
      flex: 1,
      overflowY: 'auto',
    },
    container: {
      maxWidth: isMobile ? '100%' : '1200px',
      margin: '0 auto',
      padding: isMobile ? '0' : '16px', // Removed padding for mobile to use gutter system
    },
    mobileContainer: {
      display: isMobile ? 'flex' : 'none',
      flexWrap: 'wrap',
      marginLeft: `calc(${bsGutterX} * +.7)`,
      marginRight: `calc(${bsGutterX} * +.7)`,
      marginTop: `calc(${bsGutterY} * -1)`,
    },
    mobileContentWrapper: {
      padding: `0 calc(${bsGutterX} * .5)`,
      marginTop: `calc(${bsGutterY})`,
      width: '100%',
    },
    topImageContainer: {
      position: 'relative',
      marginBottom: '24px',
      borderRadius: '15px',
      overflow: 'hidden',
    },
    topImage: {
      width: '100%',
      height: isMobile ? '200px' : '250px',
      objectFit: 'cover',
    },
    overlayText: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
      color: '#fff',
      fontWeight: 'bold',
    },
    overlayTextContent: {
      color: 'white',
      fontSize: isMobile ? '18px' : '24px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    titleSection: {
      marginBottom: '24px',
    },
    title: {
      fontSize: isMobile ? '22px' : '28px',
      fontWeight: 'bold',
      marginBottom: '12px',
    },
    description: {
      lineHeight: '1.6',
      color: '#000',
      fontSize: isMobile ? '15px' : '16px',
    },
    imageGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '16px',
    },
    gridImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      cursor: 'pointer',
      borderRadius: '8px',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
    },
    modalContent: {
      position: 'relative',
      maxWidth: '90%',
      maxHeight: '90%',
    },
    modalImage: {
      width: '100%',
      height: '100%',
      maxHeight: '90vh',
      objectFit: 'contain',
    },
    closeButton: {
      position: 'absolute',
      top: '-30px',
      right: '-30px',
      fontSize: '24px',
      color: 'white',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.layout}>
      {/* Show Desktop Navigation for non-mobile */}
      {!isMobile && (
        <div style={styles.sidebar}>
          <DesktopNavigation />
        </div>
      )}
      
      <div style={styles.content}>
        {isMobile ? (
          <div style={styles.mobileContainer}>
            <div style={styles.mobileContentWrapper}>
              <div style={styles.topImageContainer}>
                <img src={data.mainImage} alt="Main Image" style={styles.topImage} />
                <div style={styles.overlayText}>
                  <p style={styles.overlayTextContent}>{data.claim}</p>
                </div>
              </div>
              
              <div style={styles.titleSection}>
                <h1 style={styles.title}>{data.title}</h1>
                <p style={styles.description}>{data.description}</p>
              </div>
              
              <MobileImageGallery images={formattedImages} inTravelPage={true} />
            </div>
          </div>
        ) : (
          <div style={styles.container}>
            <div style={styles.topImageContainer}>
              <img src={data.mainImage} alt="Main Image" style={styles.topImage} />
              <div style={styles.overlayText}>
                <p style={styles.overlayTextContent}>{data.claim}</p>
              </div>
            </div>
            
            <div style={styles.titleSection}>
              <h1 style={styles.title}>{data.title}</h1>
              <p style={styles.description}>{data.description}</p>
            </div>
            
            <div style={styles.imageGrid}>
              {data.gridImages.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Grid Image ${index + 1}`}
                  style={styles.gridImage}
                  onClick={() => openModal(src)}
                />
              ))}
              
              {selectedImage && (
                <div style={styles.modalOverlay} onClick={closeModal}>
                  <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                    <button style={styles.closeButton} onClick={closeModal}>
                      &times;
                    </button>
                    <img 
                      src={selectedImage} 
                      alt="Selected" 
                      style={styles.modalImage} 
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TravelPageTemplate;