import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, ChevronDown, Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';
import { travelData } from '../navigation/DesktopNavigation';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isBlogOpen, setIsBlogOpen] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const styles = {
    nav: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      borderBottom: '1px solid #f3f4f6',
      zIndex: 1000,
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '24px 32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      fontSize: '1.25rem',
      fontWeight: 600,
      letterSpacing: '-0.025em',
      color: '#111827',
      textDecoration: 'none',
    },
    menuDesktop: {
      display: isMobile ? 'none' : 'flex',
      alignItems: 'center',
      gap: '48px',
    },
    menuMobile: {
      display: isMobile && isMenuOpen ? 'flex' : 'none',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white',
      zIndex: 1001,
      overflowY: 'auto',
    },
    mobileHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px 32px',
      borderBottom: '1px solid #f3f4f6',
    },
    mobileMenuItems: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    mobileMenuItem: {
      padding: '24px 32px',
      borderBottom: '1px solid #f3f4f6',
      fontSize: '1.5rem',
      color: '#111827',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mobileBlogItems: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: '#f9fafb',
    },
    mobileBlogItem: {
      padding: '20px 48px',
      borderBottom: '1px solid #f3f4f6',
      fontSize: '1.25rem',
      color: '#4b5563',
      textDecoration: 'none',
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '32px',
    },
    link: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: '#111827',
      textDecoration: 'none',
      transition: 'color 0.2s ease',
      cursor: 'pointer',
    },
    blogButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: '#111827',
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
    },
    dropdownContainer: {
      position: 'relative',
    },
    dropdownMenu: {
      position: 'absolute',
      top: '100%',
      left: 0,
      backgroundColor: 'white',
      border: '1px solid #f3f4f6',
      borderRadius: '4px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      minWidth: '150px',
      zIndex: 1002,
      marginTop: '8px',
    },
    dropdownItem: {
      padding: '12px 16px',
      fontSize: '0.875rem',
      color: '#111827',
      textDecoration: 'none',
      display: 'block',
      borderBottom: '1px solid #f3f4f6',
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '24px',
    },
    menuToggle: {
      display: isMobile ? 'block' : 'none',
      background: 'none',
      border: 'none',
      padding: '8px',
      cursor: 'pointer',
      color: 'red'
    },
    subscribeButton: {
      backgroundColor: 'black',
      color: 'white',
      padding: '8px 16px',
      fontSize: '0.875rem',
      fontWeight: 500,
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      padding: '8px',
      cursor: 'pointer',
    }
  };

  const menuItems = [
    { name: 'Work', route: '/' },
    { name: 'Contact', route: '/contact' },
  ];

  const toggleBlogDropdown = () => {
    setIsBlogOpen(!isBlogOpen);
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/photolevs', '_blank');
  };

  return (
    <nav style={styles.nav}>
      <div style={styles.container}>
        <Link to="/" style={styles.logo}>
         photoLevs.
        </Link>
        
        {/* Mobile Menu Toggle */}
        <button 
          style={styles.menuToggle}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu size={24} />
        </button>

        {/* Desktop Menu */}
        <div style={styles.menuDesktop}>
          <div style={styles.linkContainer}>
            <Link
              to="/"
              style={styles.link}
              onMouseEnter={(e) => (e.target.style.color = '#f97316')}
              onMouseLeave={(e) => (e.target.style.color = '#111827')}
            >
              Work
            </Link>
            
            {/* Blog Dropdown for Desktop */}
            <div style={styles.dropdownContainer}>
              <button 
                style={styles.blogButton}
                onClick={toggleBlogDropdown}
                onMouseEnter={(e) => (e.target.style.color = '#f97316')}
                onMouseLeave={(e) => (e.target.style.color = '#111827')}
              >
                Blog
                {isBlogOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              </button>
              
              {isBlogOpen && (
                <div style={styles.dropdownMenu}>
                  {Object.keys(travelData).map((country) => (
                    <Link
                      key={country}
                      to={`/${country}`}
                      style={styles.dropdownItem}
                      onClick={() => setIsBlogOpen(false)}
                    >
                      {country}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            
            <Link
              to="/photoLevs"
              style={styles.link}
              onMouseEnter={(e) => (e.target.style.color = '#f97316')}
              onMouseLeave={(e) => (e.target.style.color = '#111827')}
            >
              About
            </Link>
            
            <Link
              to="/contact"
              style={styles.link}
              onMouseEnter={(e) => (e.target.style.color = '#f97316')}
              onMouseLeave={(e) => (e.target.style.color = '#111827')}
            >
              Contact
            </Link>
            
            <Link
              to="/Terms"
              style={styles.link}
              onMouseEnter={(e) => (e.target.style.color = '#f97316')}
              onMouseLeave={(e) => (e.target.style.color = '#111827')}
            >
              Terms
            </Link>
          </div>
          <div style={styles.actionsContainer}>
            <button
              style={styles.subscribeButton}
              onClick={openInstagram}
              onMouseEnter={e => e.target.style.backgroundColor = '#111827'}
              onMouseLeave={e => e.target.style.backgroundColor = 'black'}
            >
              <Instagram size={16} />
              Follow!
            </button>
          </div>
        </div>

        {/* Full Screen Mobile Menu */}
        <div style={styles.menuMobile}>
          <div style={styles.mobileHeader}>
            <Link to="/" style={styles.logo}>
              photoLevs
            </Link>
            <button 
              style={styles.closeButton}
              onClick={() => setIsMenuOpen(false)}
            >
              <X size={24} color='red' />
            </button>
          </div>
          <div style={styles.mobileMenuItems}>
            <Link
              to="/"
              style={styles.mobileMenuItem}
              onClick={() => setIsMenuOpen(false)}
            >
              Work
              <ChevronRight size={24} />
            </Link>
            
            {/* Blog item with nested countries */}
            <div 
              style={styles.mobileMenuItem}
              onClick={toggleBlogDropdown}
            >
              Blog
              {isBlogOpen ? <ChevronDown size={24} /> : <ChevronRight size={24} />}
            </div>
            
            {isBlogOpen && (
              <div style={styles.mobileBlogItems}>
                {Object.keys(travelData).map((country) => (
                  <Link
                    key={country}
                    to={`/${country}`}
                    style={styles.mobileBlogItem}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {country}
                  </Link>
                ))}
              </div>
            )}
            
            <Link
              to="/photoLevs"
              style={styles.mobileMenuItem}
              onClick={() => setIsMenuOpen(false)}
            >
              About
              <ChevronRight size={24} />
            </Link>
            
            <Link
              to="/contact"
              style={styles.mobileMenuItem}
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
              <ChevronRight size={24} />
            </Link>
            
            <Link
              to="/Terms"
              style={styles.mobileMenuItem}
              onClick={() => setIsMenuOpen(false)}
            >
              Terms
              <ChevronRight size={24} />
            </Link>
            
            <div style={{...styles.mobileMenuItem, border: 'none'}}>
              <button 
                style={{
                  ...styles.subscribeButton, 
                  width: '100%',
                  justifyContent: 'center',
                  padding: '12px'
                }}
                onClick={openInstagram}
              >
                <Instagram size={20} />
                Follow on Instagram
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;