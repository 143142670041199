import React from "react";
import './Terms.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import photoLevs from "../work/photoLevs/photoLevs";
import DesktopNavigation from "../navigation/DesktopNavigation";
import { Helmet } from 'react-helmet-async';
function DesktopTerms() {
    const width = "100%";
    return ( 
        <React.Fragment>
            <Helmet>
              <title>photoLevs:  Terms and Conditions</title>
              <meta name="description" content="For the purposes of this agreement, “The Photographer” is Joseph Levermore trading as photoLevs..." />
              <meta property="og:title" content="photoLevs: Terms and Conditions" />
            </Helmet>
            <div className="layout">
        <div className="sidebar">
          <DesktopNavigation />
        </div>
            <div className="row-about">
                <div className="column">
                <h2>Terms and Conditions</h2>
                    <h3 className = "terms">Definitions</h3>
                    <p className = "about">
                        For the purposes of this agreement, “The Photographer” is Joseph Levermore trading as photoLevs. and “Images” means any images furnished by The Photographer, whether photographs, prints or other type of physical or electronic material.
                    </p>
                    <h3 className = "terms">Copyright and Ownership of Materials</h3>
                    <p className = "about">
                        The entire copyright, title, and intellectual property rights of any Images will remain that of The Photographer at all times throughout the world.
                    </p>
                    <h3 className = "terms">Right to a Credit</h3>
                    <p className = "about">
                        The Photographer asserts both his moral right to be identified as the author of his work and the right to a credit (where possible) is asserted in accordance with Sections 77 – 79 of the Copyright, Designs and Patents Act 1988 or any amendment or re-enactment thereof.  Please credit ‘photoLevs. Photography’.
                    </p>
                    <h3 className = "terms">Use</h3>
                    <p className = "about">
                        Images are to be used as agreed at the time of booking and at the time of agreeing the fee. The licence to use is limited to the use and period of time specified on The Photographers booking form and no other use may be made of the Images without the Photographers express permission. An agreement must be reached with The Photographer before Images are used for any other purpose or after the licence to use has expired.  
                            <br/>
                        Manipulation of the Images or use of only a portion of Image may only take place with the permission of the Photographer.
                            <br/>
                        When the license to use has expired an additional charge has to be agreed for extended usage with the Photographer.
                    </p>

                    <h3 className = "terms">Exclusivity</h3>
                    <p className = "about">
                        The usage rights granted are personal to the Client and may not be assigned, nor may any image submitted to the Client be loaned or transferred to third parties (save for the purpose of the work by the Client of such reproduction/usage rights/PR).
                            <br/>
                        The Photographer reserves the right to refuse to supply or grant a reproduction licence to a third party when requested to do so by the Client.
                            <br/>
                        The Photographer retains the right in all cases to use the Images covered by this agreement at any time and in any part of the world for the purposes of advertising or otherwise promoting his work.
                    </p>

                    <h3 className = "terms">Archiving of Images</h3>
                    <p className = "about">
                        All final images will be stored and archived by The Photographer for 1 year.  If archived photographs are required after that time or additional storage time is required an admin. fee may be charged.  If The Photographer loses images stored in archive or they are stolen, The Photographer is not liable beyond 1 year of storage.  Within 1 year, The Photographer is liable to the cost of the originals (a return of the original fee).
                    </p>

                    <h3 className = "terms">Applicable Law</h3>
                    <p className = "about">
                        This agreement shall be governed by the laws of England and Wales
                    </p>
                    <h3 className = "terms">Variation</h3>
                    <p className = "about">
                        No variation of terms and conditions set out herein shall be effective unless agreed in writing by both parties.
                    </p>
                </div>
                <div className="column">
                <div class="vertical-center">
                    <div className = "imageContainerTerms">
                            <LazyLoadImage
                                src={photoLevs.imageSrc} // use normal <img> attributes as props
                                width = {width}
                                alt = {photoLevs.desc}
                            />  
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
        );
    }
    export default DesktopTerms;