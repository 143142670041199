import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Header, Footer, About, Work, Contact, Terms, NotFound, firebase, DesktopWork, DesktopTerms, DesktopAbout, DesktopContact } from './components/common/';
import TravelPageTemplate from './components/common/work/travelPageTemplate/TravelPageTemplate';
import { HelmetProvider } from 'react-helmet-async';

// Create a ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    
    window.addEventListener('resize', checkScreenWidth);
    checkScreenWidth();
    
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);
  
  return (
    <HelmetProvider>
      <Router>
        {/* Add the ScrollToTop component inside Router */}
        <ScrollToTop />
        
        {isMobile ? (
          <Header />
        ) : (
          <></>
        )}
        
        <Routes>
          <Route path="/" element={isMobile ? <Work /> : <DesktopWork />} />
          <Route path="/photoLevs" element={isMobile ? <About /> : <DesktopAbout />} />
          <Route path="/contact" element={isMobile ? <Contact /> : <DesktopContact />} />
          <Route path="/Terms" element={isMobile ? <Terms /> : <DesktopTerms />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/:country" element={<TravelPageTemplate />} />
        </Routes>
        
        <Footer/>
      </Router>
    </HelmetProvider>
  );
}

export default App;