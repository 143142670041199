// Updated Work component with mobile detection and gallery implementation
import React, { useState, useEffect } from 'react';
import images from "./images/Images";
import "./Work.css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useInView } from 'react-intersection-observer';
import MobileImageGallery from './MobileImageGallery';
import { Helmet } from 'react-helmet-async';

function Work() {
  const analytics = getAnalytics();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shuffledImages, setShuffledImages] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  
  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Common breakpoint for mobile devices
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Shuffle images on component mount
  useEffect(() => {
    const shuffleImages = () => {
      const shuffled = [...images];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      setShuffledImages(shuffled);
    };
    
    shuffleImages();
  }, []);

  // Lazy loaded image component (kept from original code)
  const LazyImage = ({ src, alt, onClick, className }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: '0px 0px 0px 0px',
    });

    return (
      <div ref={ref} className={`lazy-image ${inView ? 'visible' : ''}`}>
        {inView && <img src={src} alt={alt} onClick={onClick} className={className} />}
      </div>
    );
  };

  // Original modal functionality
  const openModal = (image) => {
    setSelectedImage(image);
    const imageName = getImageName(image);
    logEvent(analytics, 'image_enlarged', {
      image_name: `image_enlarged_${imageName}`
    });
  };
  
  const closeModal = () => {
    setSelectedImage(null);
  };

  function getImageName(image) {
    if (image) {
      return image.desc;
    } else {
      return 'Unknown Image';
    }
  }

  if (shuffledImages.length === 0) {
    return <div>No images available</div>;
  }

  // Render mobile gallery if on mobile device, otherwise render desktop layout
  if (isMobile) {
    return (
      <React.Fragment>
        <Helmet>
          <title>photoLevs</title>
          <meta name="description" content="Work by photoLevs, aka Joseph Levermore. I hope you enjoy...." />
          <meta property="og:title" content="photoLevs" />
        </Helmet>
        <MobileImageGallery images={shuffledImages} />
      </React.Fragment>
    );
  }

  // Desktop layout (original implementation)
  return (
    <React.Fragment>
        <Helmet>
          <title>photoLevs</title>
          <meta name="description" content="Work by photoLevs, aka Joseph Levermore. I hope you enjoy...." />
          <meta property="og:title" content="photoLevs" />
        </Helmet>
      <center>
        <div className="image-gallery">
          {shuffledImages.map((image, index) => (
            <div className="image-item" key={index}>
              {image && (
                <img 
                  src={image.imageSrc} 
                  alt={image.caption} 
                  onClick={() => openModal(image)} 
                  className="image"
                />
              )}
            </div>
          ))}
          
          {selectedImage && (
            <div className="modal-overlay">
              <div className="modal-content">
                <button className="modal-close" onClick={closeModal}>
                  &times;
                </button>
                {selectedImage && (
                  <>
                    <img 
                      src={selectedImage.imageSrc} 
                      alt={selectedImage.caption} 
                      className="modal-image"
                    />
                    <div className="modal-caption">{selectedImage.caption}</div>
                    <div className="modal-text">
                      <div className="modal-caption">{selectedImage.desc}</div>
                      {selectedImage.showLink && (
                        <a 
                          href={selectedImage.link} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          please
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </center>
    </React.Fragment>
  );
}

export default Work;