import React, { useState, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';
import photoLevs from "../work/photoLevs/photoLevs";

function About() {
  const [isMobile, setIsMobile] = useState(false);
  
  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const styles = {
    container: {
      padding: isMobile ? '20px 16px' : '40px 20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    pageTitle: {
      fontSize: isMobile ? '24px' : '32px',
      fontWeight: '700',
      marginBottom: '24px',
      textAlign: isMobile ? 'center' : 'left',
      color: '#333',
    },
    rowAbout: {
      display: 'flex',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      gap: '30px',
    },
    column: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
    },
    verticalCenter: {
      width: '100%',
    },
    sectionTitle: {
      fontSize: isMobile ? '20px' : '24px',
      fontWeight: '600',
      marginBottom: '16px',
      color: '#333',
    },
    paragraph: {
      fontSize: isMobile ? '15px' : '16px',
      lineHeight: '1.7',
      marginBottom: '16px',
      color: '#444',
    },
    imageContainer: {
      width: '100%',
      marginBottom: isMobile ? '30px' : '0',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    },
    imageStyle: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>photoLevs: About</title>
        <meta name="description" content="Joseph Levermore, born in Birmingham, United Kingdom, was brought up in a family passionate..." />
        <meta property="og:title" content="photoLevs: About" />
      </Helmet>
      
      <div style={styles.container}>
        <h2 style={styles.pageTitle}>About photoLevs.</h2>
        
        <div style={styles.rowAbout}>
          <div style={styles.column}>
            <div style={styles.verticalCenter}>
              <h2 style={styles.sectionTitle}>About Joseph</h2>
              <p style={styles.paragraph}>
                Joseph Levermore, born in Birmingham, United Kingdom, was raised in a family passionate about art and its ability to express an individual's daily experiences. In realising the emotive power of photography, Joseph from a young age, became captivated and thus began his journey.
              </p>
              <p style={styles.paragraph}>
                In a world bursting with colour, Joseph wishes to challenge his audience by experiencing the world of duality: Black and White. Joseph sees Black and White photography as a medium to inspire individuals to project their own stories to images devoid of colour, but flushing in beauty.
              </p>
              <p style={styles.paragraph}>
                He is primarily interested in communicating the everyday experiences of city folk across the United Kingdom, and much of the work displayed here will cover those experiences. His photography is not centralised on human-based subjects but on the cityscape in the main.
              </p>
              <p style={styles.paragraph}>
                He hopes you enjoy his impressions of the United Kingdom's cityscape. The stories captured through his lens are yours to tell….
              </p>
            </div>
          </div>
          
          <div style={styles.column}>
            <div style={styles.verticalCenter}>
              <div style={styles.imageContainer}>
                <LazyLoadImage
                  src={photoLevs.imageSrc}
                  width="100%"
                  effect="blur"
                  alt={photoLevs.desc}
                  style={styles.imageStyle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default About;